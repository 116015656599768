import { GatsbySeo } from 'gatsby-plugin-next-seo'
import HelpButton from 'src/components/ui/HelpButton/HelpButton'
import Icon from 'src/components/ui/Icon'

function Page() {
  return (
    <>
      <GatsbySeo noindex nofollow />
      <div className="container-error h-screen w-full overflow-hidden bg-cover bg-center relative">
        <div className="container-blue right-48 w-full h-full absolute max-w-[490px]" />
        <div className="blue-bar">
          <Icon
            name="BlueBar"
            height={56}
            className="absolute w-full bottom-64 h-14"
            fillOpacity="0.7"
          />
        </div>
        <div className="mobile-container-blue w-full h-full max-h-[256px] absolute bottom-0" />

        <div className="mobile-error-404 text-center not-italic flex flex-col items-center absolute right-[14.5rem] top-[14rem] md:top-56 justify-center">
          <h1 className="text-white text-6xl font-semibold m-0">OPS!</h1>
          <p className="text-white my-4 text-xs">(Erro 500)</p>
          <h2 className="text-white text-base">
            Ops! Parece que estamos com algum problema de conexão.
            <br />
            Tente novamente mais tarde.
          </h2>
        </div>
        <HelpButton />
      </div>
    </>
  )
}

export default Page
